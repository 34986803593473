import Fetcher from "Scripts/common/fetcher";
import fetchJson from "Scripts/common/fetch-json";
import LoadingButton from 'Scripts/common/loading-button';

export default class ReEnableContact {
	constructor(parent) {
		this.parent = parent;
		this.modal = $("#js-modal-re-enable");
	}

	showModal(e) {
		console.log("ReEnableContact.showModal", e);

		new Fetcher({
			callback: this.onModalShown.bind(this),
			data:     $(e.currentTarget).data(),
			element:  "#js-re-enable-fetcher",
			modal:    this.modal,
			trigger:  $(e.currentTarget)
		});
	}

	onModalShown() {
		this.modal.find("#js-re-enable-confirm").on("click", this.onConfirmReEnable.bind(this));
	}

	async onConfirmReEnable(e) {
		console.log("ReEnableContact.onConfirmReEnable");

		const btn = new LoadingButton($(e.currentTarget));
		btn.disable();

		const { success } = await fetchJson(btn.data('endpoint'), { method: 'POST' });

		if(success) {
			btn.enable();
			this.modal.modal('hide');
			this.parent.list.fetch();
		}
	}
}