/**
 * Simple wrapper for building a FontAwesome icon in JS without having to write out all the HTML
 * 
 * Example usage:
 * 		buildIcon('fa-angle-right');
 * 		buildIcon('fa-check', { circle:true });
 */

module.exports = buildIcon;

function buildIcon(name, options) {
	options               = options || {};
	const faLibrary       = options.library || 'fa-solid';
	const faCircle        = options.circle || false;
	const faSize          = options.size || '';
	const faIconClasses   = (options.classes || '') + ((faCircle && !(options.classes || '').includes('text-')) ? ' fa-inverse' : ' ' + faSize);
	const faCircleClasses = options.circleClasses || '';
	const faFixedWidth    = (!faCircle && !options.tight) ? 'fa-fw' : '';
	const faTitle         = options.title ? `title="${options.title}"` : '';

	let faAttributes      = options.attributes || {};
	let faAttributesHtml  = '';

	if(faCircle && !faAttributes.hasOwnProperty('transform')) {
		faAttributes.transform = 'shrink-8';
	}

	for (const [key, value] of Object.entries(faAttributes)) {
		faAttributesHtml += ` data-fa-${key}="${value}"`;
	}

	const iconHtml = `<i class="${faLibrary} ${name} ${faFixedWidth} ${faIconClasses}" ${faAttributesHtml} ${faTitle}></i>`;

	if(faCircle) {
		return `<span class="fa-layers fa-fw ${faSize}"><i class="fa-solid fa-circle ${faCircleClasses}"></i>${iconHtml}</span>`;
	}

	return iconHtml;
}