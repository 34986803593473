// FontAwesome icons

// Use plugins to only use the features we need
// Old import: import { config, dom, library } from 'Vendor/@fortawesome/fontawesome-svg-core'
// Docs: https://fontawesome.com/docs/apis/javascript/plugins
import {
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	register,
	ReplaceElements,
} from 'Vendor/@fortawesome/fontawesome-svg-core/plugins.mjs';

const api = register([
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	ReplaceElements,
])

api.config.autoReplaceSvg = 'nest';
api.config.observeMutations = true;

// Solid icons

// Core
import { faAngleLeft            } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight           } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleRight';
import { faBullhorn             } from 'Vendor/@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCaretDown            } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp              } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCheck                } from 'Vendor/@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle               } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleArrowDown      } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleArrowDown';
import { faCircleArrowUp        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleArrowUp';
import { faCircleCheck          } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleMinus          } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleMinus';
import { faCirclePlus           } from 'Vendor/@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faCircleQuestion       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleQuestion';
import { faCircleXmark          } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faHeart                } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeart';
import { faMagnifyingGlass      } from 'Vendor/@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faMinus                } from 'Vendor/@fortawesome/free-solid-svg-icons/faMinus';
import { faPhone                } from 'Vendor/@fortawesome/free-solid-svg-icons/faPhone';
import { faPlus                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlus';
import { faQuoteLeft            } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight           } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteRight';
import { faSpinner              } from 'Vendor/@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfStroke       } from 'Vendor/@fortawesome/free-solid-svg-icons/faStarHalfStroke';
import { faUpRightFromSquare    } from 'Vendor/@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { faXmark                } from 'Vendor/@fortawesome/free-solid-svg-icons/faXmark';

// Navigation
import { faBars                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faBars';
import { faCircleInfo           } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faHouse                } from 'Vendor/@fortawesome/free-solid-svg-icons/faHouse';
import { faLock                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faLock';
import { faPencil               } from 'Vendor/@fortawesome/free-solid-svg-icons/faPencil';
import { faRightFromBracket     } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faRightToBracket       } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightToBracket';

// Reports
import { faDownload             } from 'Vendor/@fortawesome/free-solid-svg-icons/faDownload';
import { faFile                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faFile';
import { faFileCsv              } from 'Vendor/@fortawesome/free-solid-svg-icons/faFileCsv';
import { faFileExcel            } from 'Vendor/@fortawesome/free-solid-svg-icons/faFileExcel';
import { faFilePdf              } from 'Vendor/@fortawesome/free-solid-svg-icons/faFilePdf';

// Charity categories
import { faChild                } from 'Vendor/@fortawesome/free-solid-svg-icons/faChild';
import { faEarthAfrica          } from 'Vendor/@fortawesome/free-solid-svg-icons/faEarthAfrica';
import { faFutbol               } from 'Vendor/@fortawesome/free-solid-svg-icons/faFutbol';
import { faGraduationCap        } from 'Vendor/@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHandshakeAngle       } from 'Vendor/@fortawesome/free-solid-svg-icons/faHandshakeAngle';
import { faHelicopter           } from 'Vendor/@fortawesome/free-solid-svg-icons/faHelicopter';
import { faLeaf                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faLeaf';
import { faList                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faList';
import { faPalette              } from 'Vendor/@fortawesome/free-solid-svg-icons/faPalette';
import { faPaw                  } from 'Vendor/@fortawesome/free-solid-svg-icons/faPaw';
import { faPlaceOfWorship       } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlaceOfWorship';
import { faStethoscope          } from 'Vendor/@fortawesome/free-solid-svg-icons/faStethoscope';
import { faUsers                } from 'Vendor/@fortawesome/free-solid-svg-icons/faUsers';
import { faWheelchair           } from 'Vendor/@fortawesome/free-solid-svg-icons/faWheelchair';

// Reporting benefits
import { faChartLine            } from 'Vendor/@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartPie             } from 'Vendor/@fortawesome/free-solid-svg-icons/faChartPie';
import { faCreditCard           } from 'Vendor/@fortawesome/free-solid-svg-icons/faCreditCard';
import { faIdCard               } from 'Vendor/@fortawesome/free-solid-svg-icons/faIdCard';
import { faPiggyBank            } from 'Vendor/@fortawesome/free-solid-svg-icons/faPiggyBank';

// Products
import { faBasketShopping       } from 'Vendor/@fortawesome/free-solid-svg-icons/faBasketShopping';
import { faCalculator           } from 'Vendor/@fortawesome/free-solid-svg-icons/faCalculator';
import { faFileLines            } from 'Vendor/@fortawesome/free-solid-svg-icons/faFileLines';
import { faLightbulb            } from 'Vendor/@fortawesome/free-solid-svg-icons/faLightbulb';
import { faPlane                } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlane';

// Account
import { faAsterisk             } from 'Vendor/@fortawesome/free-solid-svg-icons/faAsterisk';
import { faEnvelope             } from 'Vendor/@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGear                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faGear';
import { faGears                } from 'Vendor/@fortawesome/free-solid-svg-icons/faGears';
import { faUser                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faUser';
import { faUserGear             } from 'Vendor/@fortawesome/free-solid-svg-icons/faUserGear';

// Nav
import { faCalendarDays         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCalendarDays';
import { faClockRotateLeft      } from 'Vendor/@fortawesome/free-solid-svg-icons/faClockRotateLeft';
import { faGift                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faGift';
import { faImage                } from 'Vendor/@fortawesome/free-solid-svg-icons/faImage';
import { faLink                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faLink';
import { faMoneyBill            } from 'Vendor/@fortawesome/free-solid-svg-icons/faMoneyBill';
import { faMoneyCheck           } from 'Vendor/@fortawesome/free-solid-svg-icons/faMoneyCheck';

// Markdown editor
import { faBold                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faBold';
import { faHeading              } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeading';
import { faItalic               } from 'Vendor/@fortawesome/free-solid-svg-icons/faItalic';
import { faListOl               } from 'Vendor/@fortawesome/free-solid-svg-icons/faListOl';
import { faRotateLeft           } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotateLeft';
import { faRotateRight          } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotateRight';

// Others
import { faAnglesUp             } from 'Vendor/@fortawesome/free-solid-svg-icons/faAnglesUp';
import { faArrowTrendUp         } from 'Vendor/@fortawesome/free-solid-svg-icons/faArrowTrendUp';
import { faBan                  } from 'Vendor/@fortawesome/free-solid-svg-icons/faBan';
import { faBriefcase            } from 'Vendor/@fortawesome/free-solid-svg-icons/faBriefcase';
import { faCircleExclamation    } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faClock                } from 'Vendor/@fortawesome/free-solid-svg-icons/faClock';
import { faCloudArrowUp         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCloudArrowUp';
import { faCommentDots          } from 'Vendor/@fortawesome/free-solid-svg-icons/faCommentDots';
import { faCompress             } from 'Vendor/@fortawesome/free-solid-svg-icons/faCompress';
import { faCopy                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faCopy';
import { faCut                  } from 'Vendor/@fortawesome/free-solid-svg-icons/faCut';
import { faDotCircle            } from 'Vendor/@fortawesome/free-solid-svg-icons/faDotCircle';
import { faFlag                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faFlag';
import { faGavel                } from 'Vendor/@fortawesome/free-solid-svg-icons/faGavel';
import { faGlobe                } from 'Vendor/@fortawesome/free-solid-svg-icons/faGlobe';
import { faImages               } from 'Vendor/@fortawesome/free-solid-svg-icons/faImages';
import { faLayerGroup           } from 'Vendor/@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faLocationDot          } from 'Vendor/@fortawesome/free-solid-svg-icons/faLocationDot';
import { faMagnifyingGlassMinus } from 'Vendor/@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus';
import { faMagnifyingGlassPlus  } from 'Vendor/@fortawesome/free-solid-svg-icons/faMagnifyingGlassPlus';
import { faMedal                } from 'Vendor/@fortawesome/free-solid-svg-icons/faMedal';
import { faMobileScreenButton   } from 'Vendor/@fortawesome/free-solid-svg-icons/faMobileScreenButton';
import { faPersonRunning        } from 'Vendor/@fortawesome/free-solid-svg-icons/faPersonRunning';
import { faPercent              } from 'Vendor/@fortawesome/free-solid-svg-icons/faPercent';
import { faPlay                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlay';
import { faQrcode               } from 'Vendor/@fortawesome/free-solid-svg-icons/faQrcode';
import { faRectangleList        } from 'Vendor/@fortawesome/free-solid-svg-icons/faRectangleList';
import { faRotate               } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotate';
import { faShareNodes           } from 'Vendor/@fortawesome/free-solid-svg-icons/faShareNodes';
import { faSterlingSign         } from 'Vendor/@fortawesome/free-solid-svg-icons/faSterlingSign';
import { faThumbsUp             } from 'Vendor/@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faTicket               } from 'Vendor/@fortawesome/free-solid-svg-icons/faTicket';
import { faTicketSimple         } from 'Vendor/@fortawesome/free-solid-svg-icons/faTicketSimple';
import { faTrashCan             } from 'Vendor/@fortawesome/free-solid-svg-icons/faTrashCan';
import { faTree                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faTree';
import { faTrophy               } from 'Vendor/@fortawesome/free-solid-svg-icons/faTrophy';
import { faUpDown               } from 'Vendor/@fortawesome/free-solid-svg-icons/faUpDown';
import { faUserCheck            } from 'Vendor/@fortawesome/free-solid-svg-icons/faUserCheck';
import { faUsersGear            } from 'Vendor/@fortawesome/free-solid-svg-icons/faUsersGear';
import { faWandMagicSparkles    } from 'Vendor/@fortawesome/free-solid-svg-icons/faWandMagicSparkles';

api.library.add({

	// Core
	faAngleLeft,
	faAngleRight,
	faBullhorn,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCircle,
	faCircleArrowDown,
	faCircleArrowUp,
	faCircleCheck,
	faCircleMinus,
	faCirclePlus,
	faCircleQuestion,
	faCircleXmark,
	faHeart,
	faMagnifyingGlass,
	faMinus,
	faPhone,
	faPlus,
	faQuoteLeft,
	faQuoteRight,
	faSpinner,
	faStar,
	faStarHalfStroke,
	faUpRightFromSquare,
	faXmark,

	// Navigation
	faBars,
	faCircleInfo,
	faHouse,
	faLock,
	faPencil,
	faRightFromBracket,
	faRightToBracket,

	// Reports
	faDownload,
	faFile,
	faFileCsv,
	faFileExcel,
	faFilePdf,

	// Charity categories
	faChild,
	faEarthAfrica,
	faFutbol,
	faGraduationCap,
	faHandshakeAngle,
	faHelicopter,
	faLeaf,
	faList,
	faPalette,
	faPaw,
	faPlaceOfWorship,
	faStethoscope,
	faUsers,
	faWheelchair,

	// Reporting benefits
	faChartLine,
	faChartPie,
	faCreditCard,
	faIdCard,
	faPiggyBank,

	// Products
	faBasketShopping,
	faCalculator,
	faFileLines,
	faLightbulb,
	faPlane,
	
	// Account
	faAsterisk,
	faEnvelope,
	faGear,
	faGears,
	faUser,
	faUserGear,

	// Nav
	faCalendarDays,
	faClockRotateLeft,
	faGift,
	faImage,
	faLink,
	faMoneyBill,
	faMoneyCheck,

	// Markdown editor
	faBold,
	faHeading,
	faItalic,
	faListOl,
	faRotateLeft,
	faRotateRight,

	// Others
	faAnglesUp,
	faArrowTrendUp,
	faBan,
	faBriefcase,
	faCircleExclamation,
	faClock,
	faCloudArrowUp,
	faCommentDots,
	faCompress,
	faCopy,
	faCut,
	faDotCircle,
	faFlag,
	faGavel,
	faGlobe,
	faImages,
	faLayerGroup,
	faLocationDot,
	faMagnifyingGlassMinus,
	faMagnifyingGlassPlus,
	faMedal,
	faMobileScreenButton,
	faPersonRunning,
	faPercent,
	faPlay,
	faQrcode,
	faRectangleList,
	faRotate,
	faShareNodes,
	faSterlingSign,
	faThumbsUp,
	faTicket,
	faTicketSimple,
	faTrashCan,
	faTree,
	faTrophy,
	faUpDown,
	faUserCheck,
	faUsersGear,
	faWandMagicSparkles,
})

// Regular icons

import { faCircle      as farCircle      } from 'Vendor/@fortawesome/free-regular-svg-icons/faCircle';
import { faCircleCheck as farCheckCircle } from 'Vendor/@fortawesome/free-regular-svg-icons/faCircleCheck';
import { faHeart       as farHeart       } from 'Vendor/@fortawesome/free-regular-svg-icons/faHeart';
import { faStar        as farStar        } from 'Vendor/@fortawesome/free-regular-svg-icons/faStar';

api.library.add({
	farCheckCircle,
	farCircle,
	farHeart,
	farStar,
})

// Brand icons
import { faFacebook          } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF         } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faInstagram         } from 'Vendor/@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn        } from 'Vendor/@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faStrava            } from 'Vendor/@fortawesome/free-brands-svg-icons/faStrava';
import { faStripe            } from 'Vendor/@fortawesome/free-brands-svg-icons/faStripe';
import { faWordpress         } from 'Vendor/@fortawesome/free-brands-svg-icons/faWordpress';
import { faWhatsapp          } from 'Vendor/@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter          } from 'Vendor/@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube           } from 'Vendor/@fortawesome/free-brands-svg-icons/faYoutube';

api.library.add({
	faFacebook,
	faFacebookF,
	faFacebookMessenger,
	faInstagram,
	faLinkedinIn,
	faStrava,
	faStripe,
	faWhatsapp,
	faWordpress,
	faXTwitter,
	faYoutube,
});

// Add our own icons
import { faContactless } from 'Scripts/common/icons/faContactless';

api.library.add({faContactless});

// Replace and watch
api.dom.i2svg();
api.dom.watch();