// Misc things that should run on every page of every site.

const AnalyticsEvent = require('Scripts/common/analytics-event').default;
const Cookie         = require('Vendor/js-cookie');
const ModalQueue     = require("Scripts/common/modal-queue");

module.exports = function Global() {

	new ModalQueue(); // attaches to window.modalQueue, there should be only one of these objects.

	// Stop multiple modals opening
	$('.modal').on('show.bs.modal', e => {
		$('.modal.show').not(this).each((i, modal) => {
			console.log("Global", "Closing other modals");
			$(modal).modal('hide');
			
			// As open and close events overlap, this class can get lost so manually set
			setTimeout(() => {
				$('body').addClass('modal-open');
			}, 1000);
		});

		// Send modal open event
		AnalyticsEvent.heap('ViewModal', {modalId: e.currentTarget.id});
	});

	// Is it iPad OS? Slightly hacky I know, but the cookie can get picked up by BrowserInformation for a fuller override
	if(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !Cookie.get("iPadOS")) {
		console.log("Setting iPadOS cookie");
		Cookie.set("iPadOS", true, {expires:365});
	}
}