// JS for contacts management, dealing with both charities and organisations.
// @author Matthew Norris

const DeleteContact = require('Scripts/admin/delete-contact');
const EditContact   = require('Scripts/admin/edit-contact');
const Utilities     = require('Scripts/admin/utilities');

import Fetcher from "Scripts/common/fetcher";
import ReEnableContact from 'Scripts/admin/re-enable-contact';

export default class Contacts {

	// Set up the interface
	constructor() {
		this.utilities       = new Utilities();
		this.editContact     = new EditContact(this);
		this.deleteContact   = new DeleteContact(this);
		this.reEnableContact = new ReEnableContact(this);
		
		this.list = new Fetcher({
			element:  "#js-contacts-fetcher",
			callback: this.bindControls.bind(this),
		});
	}

	// Bind controls on the list of contacts
	bindControls() {
		$("#contacts-new").click(e => {
			this.editContact.showModal();
		});
		
		$(".contacts-edit").click(e => {
			this.editContact.showModal($(e.currentTarget).data("contact-id"));
		});
		
		$(".contacts-delete").click(e => {
			this.deleteContact.showModal($(e.currentTarget).data("contact-id"));
		});

		$(".contacts-enable").click(e => {
			this.reEnableContact.showModal(e);
		});
		
		$(".contacts-resend-link").click(e => {
			this.resendActivationLink($(e.currentTarget).data("contact-id"));
		})
	}

	// Resend an admin activation link
	resendActivationLink(contactId) {
		$("#contacts-resend-link_"+contactId).hide();
		$("#contacts-resend-link-msg_"+contactId).text("Resending...")
		$("#contacts-resend-link-msg_"+contactId).show();
		
		$.ajax(this.getEndpoint("contacts/resend-activation-link"), {
			method: "POST", 
			data: {"contactId": contactId}
		}).done((response) => {
			$("#contacts-resend-link-msg_"+contactId).text("Activation link re-sent.")
		});
	}

	getEndpoint(suffix) {
		return this.utilities.isInternalAdmin() ? this.utilities.getCrmEndpoint(suffix) : "/charity/" + suffix;
	}
}