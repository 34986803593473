// There are some parts of Bootstrap we don't want, so by copying this file and commenting out some imports below, we can save on file size :)

import Alert from 'Vendor/bootstrap/js/dist/alert';
import Button from 'Vendor/bootstrap/js/dist/button';
// import Carousel from 'Vendor/bootstrap/js/dist/carousel';
import Collapse from 'Vendor/bootstrap/js/dist/collapse';
// import Dropdown from 'Vendor/bootstrap/js/dist/dropdown';
import Modal from 'Vendor/bootstrap/js/dist/modal';
// import Popover from 'Vendor/bootstrap/js/dist/popover';
// import Scrollspy from 'Vendor/bootstrap/js/dist/scrollspy';
import Tab from 'Vendor/bootstrap/js/dist/tab';
// import Toast from 'Vendor/bootstrap/js/dist/toast';
// import Tooltip from 'Vendor/bootstrap/js/dist/tooltip';

// Make it a global variable - means we can keep Bootstrap in core.js and use in other JS files without needing to import
window.bootstrap = {
	Alert: Alert,
	Button: Button,
	// Carousel: Carousel,
	Collapse: Collapse,
	// Dropdown: Dropdown,
	Modal: Modal,
	// Popover: Popover,
	// Scrollspy: Scrollspy,
	Tab: Tab,
	// Toast: Toast,
	// Tooltip: Tooltip,
}

// Update Bootstrap to open modal with keyboard for <a> elements (does it by default for <button>)
document.querySelectorAll("main a:not(.btn)[data-bs-toggle='modal'][data-bs-target]").forEach(el => {
	el.addEventListener("keydown", e => {
		if(e.keyCode == 13 || e.keyCode == 32) {
			console.log("Keyboard opens modal", e.currentTarget);
			e.currentTarget.click();
		}
	});
});